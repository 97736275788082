<script>
import DocumentListComponent from "./components/resources/DocumentListComponent.vue";
import AutoriryComponent from "./components/resources/AutoriryComponent.vue";
import NewsComponent from "./components/resources/NewsComponent.vue";
import ITCGridComponent from "./components/resources/ITCGridComponent.vue";
import BoxIconGridComponent from "./components/resources/BoxIconGridComponent.vue";
import MultiSliderComponent from "./components/resources/MultiSliderComponent.vue";
import FrecuentAccessComponent from "./components/resources/FrecuentAccessComponent.vue";
import GridLinkComponent from "./components/resources/GridLinkComponent.vue";
import CardGridComponent from "./components/resources/CardGridComponent.vue";
import BoletinComponent from "./components/resources/BoletinComponent.vue";
export default {
  name: "HomeView",
  components: {
    /* eslint-disable */
    DocumentListComponent,
    /* eslint-disable */
    AutoriryComponent,
    /* eslint-disable */
    NewsComponent,
    /* eslint-disable */
    ITCGridComponent,
    /* eslint-disable */
    BoxIconGridComponent,
    /* eslint-disable */
    MultiSliderComponent,
    /* eslint-disable */
    FrecuentAccessComponent,
    /* eslint-disable */
    GridLinkComponent,
    /* eslint-disable */
    CardGridComponent,
    BoletinComponent,
  },
  data() {
    return {
      site: 29,
    };
  },
  mounted() {
    document.title = process.env.VUE_APP_TITLE;
  },
};
</script>

<template>
<BoletinComponent
    :resultados="10"
    :show-fecha="false"
    titulo="Boletín Oficial"
    :filtros="{ site: 'dlc.filtroanioymes.boletinoficial' }"
  />
  <DocumentListComponent
    :resultados="10"
    :show-fecha="false"
    titulo="Boletín Oficial"
    :filtros="{ site: 'dlc.tramites.boletinoficial' }"
  />

  <!-- Links Utiles -->
  <GridLinkComponent
    :columnas="4"
    titulo="Links Útiles"
    :data="[
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Ministerio de Hacienda y Finanzas',
        url: 'https://hacienda.sanjuan.gob.ar/',
      },
      // {
      //   subtitle: 'Página web',
      //   target: '_blank',
      //   title: 'Secretaria de la Gestión Pública',
      //   url: 'https://secretariak8s.sanjuan.gob.ar/',
      // },
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Dirección Provincial de Informática',
        url: 'https://dpik8s.sanjuan.gob.ar/',
      },
      // {
      //   subtitle: 'Página web',
      //   target: '_blank',
      //   title: 'Dirección de Ciberseguridad',
      //   url: 'https://ciberseguridadk8s.sanjuan.gob.ar/',
      // },
      // {
      //   subtitle: 'Página web',
      //   target: '_blank',
      //   title:
      //     'Dirección de Recursos Humanos y Organización del Empleo Público',
      //   url: 'https://rrhhk8s.sanjuan.gob.ar/',
      // },
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'IIEE - Instituto de Investigaciones Económicas y Estadísticas',
        url: 'https://web.sanjuan.gob.ar/iiee/',
      },
      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Ciudadano Digital',
        url: 'https://cidi.sanjuan.gob.ar/',
      },
      // {
      //   subtitle: 'Página web',
      //   target: '_blank',
      //   title: 'Innova',
      //   url: 'http://innovak8s.sanjuan.gob.ar/',
      // },

      {
        subtitle: 'Página web',
        target: '_blank',
        title: 'Compras Públicas',
        url: 'https://compraspublicas.sanjuan.gob.ar/',
      },
    ]"
  />
</template>
