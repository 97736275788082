<template>
  <section>
    <div class="container">
      <h2 class="pb-4">Consulta</h2>
      <form class="border rounded m-5 p-3" @submit.prevent="buscarPorMesYAnio">
        <label class="p-2">Búsqueda por mes y año</label>
        <div class="form-group row p-2">
          <div class="col-lg-10 col-12">
            <div class="row justify-content-center">
              <label class="col-1 col-form-label" for="Mes">Mes:</label>
              <div class="col-lg-4">
                <select v-model="mes" class="form-control" name="Mes">
                  <option value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
              <label class="col-1 col-form-label" for="Anio">Año:</label>
              <div class="col-lg-5">
                <input v-model="anio" class="form-control" name="Anio" placeholder="Ej: 2023" />
              </div>
            </div>
          </div>
          <div class="col-lg-2 col-2 pt-lg-0 pt-3">
            <button class="btn btn-secondary text-uppercase" type="submit">
              <i aria-hidden="true"></i> Buscar
            </button>
          </div>
        </div>
      </form>
      <div class="row row-cols-1 g-4">
        <div class="col" v-for="(el, index) in items" :key="index">
          <div class="card">
            <div class="card-body p-md-1 d-flex flex-md-row align-items-md-center flex-column">
              <div class="contenedor-icono d-inline-flex align-items-center justify-content-center flex-shrink-0 me-3">
                <svg class="ptn-i-documento text-ptn-color-gsj-2" v-if="!el.icon">
                  <use xlink:href="#ptn-i-documento"></use>
                </svg>
                <svg class="ptn-i-documento text-ptn-color-gsj-2" v-if="el.icon">
                  <use v-bind="{ 'xlink:href': '#' + el.icon }"></use>
                </svg>
              </div>
              <p class="card-text m-md-0" style="min-width: 90px" v-if="showFecha">
                {{ formatDate(el.publish_up) }}
              </p>
              <h3 class="card-title m-md-0 px-md-4 h6 text-uppercase">
                <a v-bind:href="el.url" v-bind:target="el.target ? el.target : '_self'" class="stretched-link">{{ el.title
                }}</a>
              </h3>
            </div>
          </div>
        </div>
        <div class="col" v-if="cargando">
          <div class="card loading-card-load" style="min-height: 3px"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios = require("axios");
export default {
  name: "BoletinComponent",
  props: {
    titulo: String,
    filtros: Object,
    data: Object,
    resultados: Number,
    showFecha: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hash: "DLC",
      items: [],
      current_page: 1,
      results: 5,
      cargando: false,
      nomore: false,
      showMore: true,
      mes: "",
      anio: "",
    };
  },
  methods: {
    async buscarPorMesYAnio() {
      this.cargando = true;
      this.items = [];

      const tipo = 'por_mes_anio';
      const params = {
        site: 'dlc.filtroanioymes.boletinoficial',
        tipo,
        mes: this.mes,
        anio: this.anio,
      };

      try {
        const response = await axios.post(process.env.VUE_APP_API_URL, params);
        this.cargando = false;
        console.log(response)
        if (response && response.data) {
          this.items = response.data.items || [];
          this.total_items = response.data.total_items || null;
          this.showMore = this.items.length < this.total_items;
        } else {
          console.error('La respuesta de la API no contiene datos válidos.');
        }
      } catch (error) {
        this.cargando = false;
        console.error('Error al obtener los boletines:', error);
      }
    },

    formatDate(dateStr) {
      const date = new Date(dateStr);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },
  },
};
</script>