<template>
    <div class="container p-4" style="min-height: 150px;" >
        <div style="text-align:center; overflow-wrap: break-word;" v-html=" this.html">
        </div>
        <div class="text-center pt-4">
            <p><button v-on:click="back" class="btn btn-secondary text-uppercase">Volver</button></p>
        </div>
    </div>    
</template>

<script>
const axios = require("axios");
export default {
    name: 'InfoView',
    props: {
        uid: String,
        filtros : Object
    },
    data() {
        return {
            html : null
        };
    },
    methods : {
        back : function () {
            history.back();
        }
    },
    mounted() {
        if(this.data == null){
            let params = {};
            if(this.filtros != null && this.filtros != ""){
                params = this.filtros;
            }
            params.uid = this.uid;
            axios.post(process.env.VUE_APP_API_URL,params)
            .then(response => {
                this.html = response.data ? response.data.html : null;
                localStorage.setItem(this.uid, JSON.stringify(response));
            })
            .catch (error => {
                if (!error.response) {
                    console.log("Fuera de Linea");
                    if(localStorage.getItem(this.uid)){
                        console.log("Cargando elementos de cache para " + this.uid);
                        var resp = JSON.parse(localStorage.getItem(this.uid));
                        this.html = resp.data ? resp.data.html : null 
                    }
                }
            })
        }else{
            this.html = this.data;
        }
    },
}
</script>